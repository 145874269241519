import { resolveCart } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { ShopifyCart } from "../types/custom.types";
import { AttributeInput, Cart } from "../types/storefront.types";
import { CART_ATTRIBUTES_UPDATE_MUTATION } from "../graphql";

type CartResponse = { 
  cartAttributesUpdate: {
    cart: Cart
  }
}
export const cartUpdateAttributes = async (cartId: string, attributes: AttributeInput[]): Promise<ShopifyCart> => {
  const { data, errors } = await storefrontAPIClient.request<CartResponse>(
    CART_ATTRIBUTES_UPDATE_MUTATION, 
    { 
      variables: {
        cartId,
        attributes
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.cartAttributesUpdate) throw new Error('Could not update cart attributes!');

  return resolveCart(data.cartAttributesUpdate.cart);
}