import { CART_FRAGMENT } from "./fragments/cart-fragment"

export const CART_ATTRIBUTES_UPDATE_MUTATION = `
  ${CART_FRAGMENT.fragment}
  
  mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
    cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
      cart {
        ${CART_FRAGMENT.ref}
      }
      userErrors {
        field
        message
      }
    }
  }
`
