import { Icon } from "components/elements";
import { Price } from "components/product";
import { Link } from "gatsby";
import { useDiscount } from "global";
import { getProductByHandle } from "lib/shopify";
import React, { useEffect } from "react";
import styled from "styled-components";
import { CustomerStory } from "types/misc";
import { getBasePrice, isValidCompareAtPrice, sortVariantsByPrice } from "utilities";

const ProductCard = ({ product }) => {
  const { getDiscountAmount } = useDiscount();
  if (!product) return null;

  return (
    <div className="product-card">
      <Link to={product.copy.seoPathname} title={product.title}>
        <img 
          src={product.images[0].src}
          alt={`customer story product ${product.images[0].alt || product.title}`}
        />
      </Link>
      
      <div className="product-card-details">
        <Link title={product.title} to={product.copy.seoPathname}>{product.title}</Link>
        <div className='pricing'>
          <span>Starting at</span>
          <Price
            basePrice={getBasePrice(product.variants[0])}
            discountAmount={getDiscountAmount(product.variants[0])}
            type='tiny'
          />
        </div>
      </div>
    </div>
  )
}

type Props = {
  customerStory: CustomerStory,
  layout: 'cards' | 'icons'
}
export const CustomerStoryProductList = ({ customerStory, layout }: Props) => {
  const [allShopifyProduct, setAllShopifyProduct] = React.useState<any[]>([]);

  const productHandles = {
    r3: "rachio-3", 
    sht: "rachio-smart-hose-timer", 
    wft: "tempest-rachio-wx-system-1"
  };

  const productMap = {
    [productHandles.r3]: {
      name: 'Rachio 3',
      icon: <Icon name="r3-alt" color="white" />
    },
    [productHandles.sht]: {
      name: 'Smart Hose Timer',
      icon: <Icon name="smart-hose-timer" color="white" />
    },
    [productHandles.wft]: {
      name: 'Tempest Weather System',
      icon: <Icon name="weatherflow-tempest" color="white" />
    }
  }

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const products = await Promise.all([
          getProductByHandle(productHandles.r3),
          getProductByHandle(productHandles.sht),
          getProductByHandle(productHandles.wft)
        ])
  
        if (products.length) {
          const buildCopyProduct = (product) => ({
            ...product,
            images: product.media.filter(mediaItem => mediaItem.mediaContentType === 'IMAGE').map(img => img.image),
            copy: customerStory.products.find(pf => pf.handle === product.handle),
            variants: sortVariantsByPrice(product.variants.map(v => ({ 
              ...v, 
              compareAtPrice: isValidCompareAtPrice(v) && v.compareAtPrice
            })))
          })

          const filterWhitelistedProduct = p => (
            Boolean(productMap[p.handle] && customerStory.products.find(cp => cp.handle === p.handle))
          )

          const sortProductByHandleAlpha = (a, b) => {
            const handleA = a.handle.toUpperCase();
            const handleB = b.handle.toUpperCase();
            return (handleA < handleB) ? -1 : (handleA > handleB) ? 1 : 0;
          }
          
          const shopifyProductsFiltered = products
            .map(buildCopyProduct)
            .filter(filterWhitelistedProduct)
            .sort(sortProductByHandleAlpha)

          setAllShopifyProduct(shopifyProductsFiltered);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchProducts();
  }, [])

  return (
    <Container className={`${layout}`}>
      {layout === 'icons' 
        ? (
          <div className="product-icons">
            {allShopifyProduct.map(p => (
              <div className="product-icon" key={`icon-${p.handle}`}>
                <Link title={p.title} to={p.copy.seoPathname}>{productMap[p.handle].icon}</Link>
              </div>
            ))}
          </div>
        ) : (
          <div className="product-cards">
            {allShopifyProduct.map(p => (
              <ProductCard product={p} key={`product-card-${p.handle}`} />
            ))}
          </div>
        )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  svg {
    width: 50px;
    height: 50px;
  }
  &.cards {
    .product-card {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      img {
        width: 6rem;
        border-radius: 50%;
      }
      .product-card-details {
        font-size: 1.2rem;
        a {
          font-size: 1.5rem;
        }
        margin-left: 1rem;
        .pricing {
          display: flex;
          align-items: center;
          .price, 
          .discount {
            font-size: 1.2rem;
            span:last-child {
              margin-right: 0;
            }
          }
          .price-text {
            display: flex;
          }
        }
        span {
          margin-right: .5rem;
        }
      }
    }
  }
  &.pills {
    svg {
      width: 25px;
      padding: 0;
      margin: 0;
      height: 1.4rem;
      position: relative;
      top: .2rem;
    }
    a {
      background: var(--accentColor);
      color: white;
      padding: .5rem 1.5rem;
      border-radius: 5rem;
      font-size: 1.4rem;
      margin: 1rem 0;
    }
  }
`
