import { useEffect } from 'react'
import { useCart } from 'global'
import { ExtendPlan } from './types'

declare const window

const storeId = process.env.GATSBY_EXTEND_STORE_ID

export const useExtend = (
  referenceId: string,
  type: 'buttons' | 'simple',
  loadJustSdk: boolean,
  extendId: string,
  title: string
) => {
  const { addWarrantyToCart } = useCart();

  const onAddToCart = async function({ plan }: { plan: ExtendPlan }) {
    if (plan) {
      // a user has selected a plan.  Add warranty to their cart.
      await addWarrantyToCart(plan, referenceId, title);
    }
  }

  useEffect(() => {
    //const existsExtendScript = document.getElementById('extend-script')
    if (!window.Extend) {
      let script = document.createElement('script')
      script.src =
        'https://sdk.helloextend.com/extend-sdk-client/v1/extend-sdk-client.min.js'
      script.id = 'extend-script'
      script.onload = () => {
        window.Extend.config({
          storeId,
        })
        if (loadJustSdk) return
        if (type == 'buttons') {
          window.Extend.buttons.render(`#${extendId}`, {
            referenceId,
          })
        } else {
          window.Extend.buttons.renderSimpleOffer(`#${extendId}`, {
            referenceId,
            onAddToCart,
          })
        }
      }
      document['body'].appendChild(script)
    } else {
      if (loadJustSdk) return

      if (type == 'buttons') {
        const component = window.Extend.buttons.instance(`#${extendId}`)
        if (!component)
          return window.Extend.buttons.render(`#${extendId}`, {
            referenceId,
          })
        component.setActiveProduct(referenceId)
      } else {
        window.Extend?.buttons?.renderSimpleOffer(`#${extendId}`, {
          referenceId,
          onAddToCart,
        })
      }
    }

    const existsExtendShopifyScript = document.getElementById(
      'shopify-extend-script'
    )
    if (!existsExtendShopifyScript) {
      let shopifyExtendScript = document.createElement('script')
      shopifyExtendScript.id = 'shopify-extend-script'
      shopifyExtendScript.src =
        'https://sdk.helloextend.com/extend-sdk-client-shopify-addon/v1/extend-sdk-client-shopify-addon.min.js'
      document['body'].appendChild(shopifyExtendScript)
    }
  }, [referenceId])
}
