import { CART_FRAGMENT } from "./fragments/cart-fragment"

export const CART_LINE_ITEMS_UPDATE_MUTATION = `
  ${CART_FRAGMENT.fragment}

  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ${CART_FRAGMENT.ref}
      }
      userErrors {
        field
        message
      }
    }
  }
`
