import React, { useState } from 'react'
import loadable from '@loadable/component'
import styled, { css } from 'styled-components'
import { below } from 'styles'
import {
  fetchIdFromGlobalId,
} from 'utilities'

import {
  AlertBanner,
  ProductCarousel,
  ProductRating,
} from 'components/product'
import { ProductCopy } from 'types/misc';
import { PARTNER_PRODUCT_HANDLES, CONTROLLER_PRODUCT_HANDLES } from '../../../common/config/products';
import { Dropdown } from 'components/elements'
import { ControllerZoneInfoModal } from './controller'
import { GatsbyShopifyStorefrontProduct, ShopifyProductVariant } from 'lib/shopify/storefront-api-client/types/custom.types'
const RebatesText = loadable(() => import('./controller'), {
  resolveComponent: (components) => components.RebatesText,
})
const ProductForm = loadable(() => import('components/product/forms'), {
  resolveComponent: (components) => components.ProductForm,
})
const ProductFormTiered = loadable(() => import('components/product/forms'), {
  resolveComponent: (components) => components.ProductFormTiered,
})

const ProductDescription = ({ product, productPage }) => {
  const Content = (
    <div>
      {
        productPage.displayShopifyDescriptionHTML 
          ? <div dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}></div> 
          : <p>{product.description}</p>
      }
      {productPage.rebatesEnabled && <RebatesText product={product} />}
    </div>
  )

  return (
    <div className='small-margins'>
      {productPage.descriptionAsDropdown ? (
        <Dropdown openText='Description' closeText='Description'>
          {Content}
        </Dropdown>
      ) : (
        <span>{Content}</span>
      )}
    </div> 
  )
}

type Props = {
  product: GatsbyShopifyStorefrontProduct
  productPage: ProductCopy
  productMedia: any[],
  addToCartTestId?: string
}

export function ProductOverview({
  product,
  productPage,
  productMedia,
  addToCartTestId
}: Props) {
  const [activeImageIndex, setActiveImageIndex] = useState<number | undefined>();
  const isController = productPage.handle === CONTROLLER_PRODUCT_HANDLES.RACHIO_THREE;
  const handleSelectedVariant = (variant: ShopifyProductVariant) => {
    const imageIndex = productMedia.findIndex(mediaItem => mediaItem.variantIds?.includes(variant.id));
    if (imageIndex !== -1) setActiveImageIndex(imageIndex);
  }

  return (
    <>
      <ProductOverviewWrapper topBannerText={productPage.topBannerText} quantitySelectionEnabled={productPage.quantitySelectionEnabled}>
        <div className="product-overview">
          {productPage.topBannerText && <AlertBanner text={productPage.topBannerText} />}
          <div className='product-overview-subtitle h6'>{productPage.subtitle || product.vendor}</div>
          <h1 data-testid="product-overview-title" className="no-top-margin">
            {product.title}
          </h1>

          {productPage.yotpoEnabled && (
            <div
              className="yotpo bottomLine"
              style={{ minHeight: 17.5 }}
              data-product-id={fetchIdFromGlobalId(product.id)}
            ></div>
          )}

          {!productPage.yotpoEnabled && productPage.productRating && (
            <ProductRating
              rating={productPage.productRating}
            />
          )}

          <ProductDescription product={product} productPage={productPage} />

          {isController && <ControllerZoneInfoModal />}

          {productPage.variantSelector === 'tiered' 
            ? (
              <ProductFormTiered
                productPageLike={productPage}
                shopifyProductLike={product}
                testId="product-form-tiered"
                onVariantSelection={handleSelectedVariant}
                renderTieredVariantSelectionText={(variantSelections) => {
                  if (productPage.handle.includes('nozzle')) {
                    let qtyPerPack = 3;
                    switch (productPage.handle) {
                      case PARTNER_PRODUCT_HANDLES.KRAIN_NOZZLLES:
                        qtyPerPack = 3;
                        break;
                      case PARTNER_PRODUCT_HANDLES.HUNTER_NOZZLLES:
                          qtyPerPack = 10;
                          break;
                      default:
                        qtyPerPack = 3;
                        break;
                    }

                    const variantSelectionKeys = Object.keys(variantSelections);
                    const sumQty = variantSelectionKeys.reduce((prev, cur) => variantSelections[cur].quantity + prev, 0)
                    const sumQtyPerPack = sumQty * qtyPerPack;
                    return (
                      <>
                        <span>
                          NOZZLES:{" "}
                        </span>
                        <span>
                          {sumQty} X {qtyPerPack}-pack ({sumQtyPerPack} total)
                        </span>
                      </>
                    )
                  }

                  return null
                }}
              />    
            ) : 
            (
              <ProductForm
                product={product}
                productPage={productPage}
                addToCartTestId={addToCartTestId}
                onSelectedVariant={handleSelectedVariant}
              />
            )
          }
        </div>

        <div className="carousel-wrapper">
          <ProductCarousel
            mediaItems={productMedia}
            copyProduct={{
              ...product,
              copy: productPage
            }}
            activeImageIndex={activeImageIndex}
          />
        </div>
      </ProductOverviewWrapper>
    </>
  )
}

export const ProductOverviewWrapper = styled.div<{ topBannerText?: string, quantitySelectionEnabled?: boolean }>`
  margin-bottom: var(--margin);
  display: flex;
  .product-overview-subtitle {
    margin-top: ${({ topBannerText }) => !topBannerText && `var(--largeMargin)` };
    text-transform: uppercase;
    ${below.medium`
      margin-top: 0;
    `}
  }

  ${({ quantitySelectionEnabled }) => quantitySelectionEnabled && (
    css`
      .variant-select-input {
        margin-bottom: 20px;
      }
    `
  )}

  .carousel-wrapper, .product-overview {
    width: 49%;
    padding: 0 var(--basePadding);
  }

  .carousel-wrapper > div {
    position: sticky; 
    top: 18rem;
    ${below.medium`
      position: static;
    `}
  }

  .sale-div {
    display: flex;
    align-items: center;
    padding: 0 2rem;
    background: var(--lightesterGray);
    margin-bottom: var(--margin);
    p {
      margin: var(--margin) 0;
    }
    svg {
      margin-right: var(--margin);
      height: 2.8rem;
      width: 2.8rem;
    }
  }

  @media (max-width: 900px) {
    flex-wrap: wrap;
    .carousel-wrapper, .product-overview {
      width: 100%;
      padding: 0;
    }
    .carousel-wrapper {
      margin-top: var(--largeMargin);
      order: 1;
    }
    .product-overview {
      order: 2;
    }
  }

  .carousel-wrapper {
    ${below.medium`
      padding-bottom: 0
    `};
  }

  .buy-buttons {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--margin);
    .buy-button.bundle-button {
      margin: var(--margin) 0;
    }
  }

  ${below.small`
    h1 {
      font-size: var(--h2);
    }
  `}
`
