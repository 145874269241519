import { resolveCart } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { ShopifyCart } from "../types/custom.types";
import { Cart } from "../types/storefront.types";
import { CART_DISCOUNT_CODES_UPDATE_MUTATION } from "../graphql";

type CartResponse = { 
  cartDiscountCodesUpdate: {
    cart: Cart
  }
}
export const cartUpdateDiscount = async (cartId: string, discountCodes: string[]): Promise<ShopifyCart> => {
  const { data, errors } = await storefrontAPIClient.request<CartResponse>(
    CART_DISCOUNT_CODES_UPDATE_MUTATION, 
    { 
      variables: {
        cartId,
        discountCodes
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.cartDiscountCodesUpdate) throw new Error('Could not cart discount codes!');

  return resolveCart(data.cartDiscountCodesUpdate.cart);
}