import { getGAClientId, getKlaviyoId, getRachioAccountEmail, getRachioAccountId } from "analytics"
import { AttributeInput } from "lib/shopify/storefront-api-client/types/storefront.types";

export const cartCustomAttributes = (newAttributes?: AttributeInput[]): AttributeInput[] => {
  const gaClientId = getGAClientId()
  const rachioAccountEmail = getRachioAccountEmail()
  const klaviyoId = getKlaviyoId()
  const rachioAccountId = getRachioAccountId()
  
  let attrs = [
    {
      key: 'ga_client_id',
      value: gaClientId,
    },
  ] as AttributeInput[]

  if (rachioAccountEmail) {
    attrs.push({
      key: 'rachio_account_email',
      value: rachioAccountEmail
    })
  }

  if (klaviyoId) {
    attrs.push({
      key: 'klaviyo_id',
      value: klaviyoId
    })
  }

  if (rachioAccountId) {
    attrs.push({
      key: 'rachio_account_id',
      value: rachioAccountId
    })
  }

  if (newAttributes) {
    attrs = [...attrs, ...newAttributes];
  }

  return attrs.filter(({ value }) => value)
}