import { CART_FRAGMENT } from "./fragments/cart-fragment"

export const CART_DISCOUNT_CODES_UPDATE_MUTATION = `
  ${CART_FRAGMENT.fragment}

  mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ${CART_FRAGMENT.ref}
      }
      userErrors {
        field
        message
      }
    }
  }
`
