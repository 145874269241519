import React, {
  FC,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react'
import { useStorage } from 'hooks'
import { DISOUNT_TEMPLATE_KEYWORDS, checkParams, convertDateToTime, isDateInFuture } from 'utilities'
import { isBundleInCart } from '../lib/pickystory'
import { DISCOUNT_STATUS, fetchDiscountByCode } from '../lib/retail-api'
import { Discount } from 'types/misc'
import { useCart } from './cart'

const initialDiscount: Discount = {
  code: '',
  amount: 0,
  percentage: 0,
  products: [],
  summary: ''
}

export const initialDiscountValues = {
  discount: initialDiscount,
  getDiscountAmount: (_: { price: { amount: string|number }, compareAtPrice?: { amount: string|number } | null, sku: string }) => 0,
  getDiscountedPrice: (_: { price: number, discountAmount: number }) => "",
}

export const DiscountContext = createContext(initialDiscountValues)

export const DiscountProvider: FC = ({ children }) => {
  const { cart, applyDiscount, getCart } = useCart()
  const [discount, setDiscount] = useState<Discount>(initialDiscount)
  // ? Used when giving a discount via url link
  // * Set ?discount=[CODE]
  const [discountStorage, setDiscountStorage] = useStorage({
    key: 'discount'
  })

  const isDiscountValid = (discountRes: { status: DISCOUNT_STATUS, endsAt?: string | null }) => (
    discountRes.status === DISCOUNT_STATUS.ACTIVE 
    && (!discountRes.endsAt || isDateInFuture(discountRes.endsAt))
  )

  useEffect(() => {
    checkParams('discount', async (discount) => {
      try {
        if (!discount) return;

        const discountRes = await fetchDiscountByCode(discount);       

        if (!isDiscountValid(discountRes)) {
          throw new Error('Discount is not active');
        }

        const discountTransformed = {
          ...discountRes,
          products: discountRes.variants,
          percentage: discountRes.percentage ? discountRes.percentage * 100 : null,
          isActive: discountRes.status === 'ACTIVE'
        } as Discount;

        setDiscountStorage(
          discountTransformed, 
          convertDateToTime(discountRes.endsAt || 1)
        );
        getCart();
      } catch (error) {
        localStorage.removeItem('discount');
        window.location.replace(window.location.pathname);
        console.error(error)
      }
    })
  }, [location.search]);

  useEffect(() => {
    if (discountStorage) {
      setDiscount(discountStorage);
    }
  }, [discountStorage])

  useEffect(() => {
    if (!cart?.lines.length || !discount.code || !discount.products?.length) return;
    if (!!isBundleInCart(cart)) return;

    const canApplyDiscount = cart.lines.some(item => 
      discount.products?.some(product => product.sku === item.merchandise.sku)
    );

    if (!canApplyDiscount) return;

    const discountAlreadyApplied = cart.discountCodes.some(shopifyDiscountCode => {
      if (!discount.code || !shopifyDiscountCode) return false;

      if (discount.code.trim() === shopifyDiscountCode.code) return true;

      const highPriorityDiscountApplied = Boolean(Object.values(DISOUNT_TEMPLATE_KEYWORDS).find(c => shopifyDiscountCode.code?.includes(c)));

      return highPriorityDiscountApplied && shopifyDiscountCode.applicable;
    });

    if ( !discountAlreadyApplied) {
      applyDiscount(discount.code);
    }
  }, [cart?.lines])

  // ? Helper to get the best discount based on price and type
  // * This is used to show a discounted amount for items on the site that use the Price component and are not in your cart yet
  const getDiscountAmount = ({
    price,
    compareAtPrice,
    sku,
  }: {
    price: {
      amount: number | string
    }
    compareAtPrice?: {
      amount: number | string
    } | null
    sku: string
  }): number => {
    const basePrice = Number(price.amount);

    if (compareAtPrice) {
      return Number(compareAtPrice.amount) - basePrice;
    }

    if (!discount.code || discount.code.includes(DISOUNT_TEMPLATE_KEYWORDS.TIERED)) return 0;
    const isDiscounted = discount.products?.find((product) => product.sku === sku);

    if (isDiscounted && discount.amount) {
      return discount.amount;
    }

    if (isDiscounted && discount.percentage) {
      const percentTotal = Number(basePrice) * (discount.percentage / 100);
      return percentTotal;
    }

    return 0;
  }

  const getDiscountedPrice = ({
    price,
    discountAmount
  }: {
    price: number,
    discountAmount: number
  }) => {
    const amount = price - discountAmount;
    return amount.toFixed(2);
  }

  return (
    <DiscountContext.Provider
      value={{
        discount,
        getDiscountAmount,
        getDiscountedPrice
      }}
    >
      {children}
    </DiscountContext.Provider>
  )
}

export const useDiscount = () => useContext(DiscountContext)
