import styled, { css } from 'styled-components'
import { below } from 'styles'

const outlineButtonStyle = css`
  --buttonBgColor: transparent;
  --buttonBorder: 2px solid var(--primaryColor);
  --buttonColor: var(--primaryColor);
  --buttonShadow: none;
  --buttonHoverBg: var(--primaryColor);
  --buttonHoverBorderColor: var(--primaryColor);
  --buttonHoverColor: var(--white);
`

const whiteButtonStyle = css`
  color: var(--primaryColor);
  --buttonBgColor: var(--white);
  --buttonBorder: 2px solid var(--white);
  --buttonColor: var(--primaryColor);
  --buttonShadow: none;
  --buttonHoverBg: var(--primaryColor);
  --buttonHoverColor: var(--white);
  --buttonHoverBorderColor: var(--primaryColor);
  --buttonHoverShadow: none;
`

const limeGreenButtonStyle = css`
  color: #0C2738;
  --buttonBgColor: #CDFF58;
  --buttonBorder: 2px solid #CDFF58;
  --buttonColor: #0C2738;
  --buttonShadow: none;
  --buttonHoverBg: #a3cd42;
  --buttonHoverColor: #0C2738;
  --buttonHoverBorderColor: #a3cd42;
  --buttonHoverShadow: none;
`

const blueButtonStyle = css`
  --buttonBgColor: var(--primaryColor);
  --buttonBorder: 2px solid var(--primaryColor);
  --buttonColor: var(--navyBlue);
  --buttonHoverBg: var(--accentColor);
  --buttonHoverColor: var(--white);
  --buttonHoverBorderColor: var(--accentColor);
  --buttonHoverShadow: var(--elevation-2);
`

const orangeButtonStyle = css`
  --buttonBgColor: var(--orange);
  --buttonBorder: 2px solid var(--orange);
  --buttonColor: var(--navyBlue);
  --buttonHoverBg: var(--orange);
  --buttonHoverColor: var(--navyBlue);
  --buttonHoverBorderColor: var(--orange);
  --buttonHoverShadow: var(--elevation-2);
`

const greenButtonStyle = css`
  --buttonBgColor: var(--green);
  --buttonBorder: 2px solid var(--green);
  --buttonColor: var(--navyBlue);
  --buttonHoverBg: var(--green);
  --buttonHoverColor: var(--navyBlue);
  --buttonHoverBorderColor: var(--green);
  --buttonHoverShadow: var(--elevation-2);
`
const darkButtonStyle = css`
  --buttonBgColor: var(--navyBlue);
  --buttonBorder: 2px solid var(--navyBlue);
  --buttonColor: var(--navyBlue);
  --buttonHoverBg: var(--primaryBlue);
  --buttonHoverColor: var(--white);
  --buttonHoverBorderColor: var(--primaryBlue);
  --buttonHoverShadow: var(--elevation-2);
`

export const baseButtonStyle = css`
  ${blueButtonStyle};
  background: var(--buttonBgColor);
  color: var(--white);
  border: var(--buttonBorder);
  font-weight: var(--fontWeightBold);
  &:hover {
    background: var(--buttonHoverBg);
    border-color: var(--buttonHoverBorderColor);
    color: var(--buttonHoverColor);
  }

  cursor: pointer;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  border-radius: var(--buttonRadius);
  font-size: var(--smallFontSize);
  font-family: var(--headlineFont);
  padding: var(--buttonPadding);
  transition: var(--transition);
  &:disabled {
    background: var(--lighterGray);
    border-color: var(--lighterGray);
    color: var(--white);
    cursor: auto;
    &:hover {
      background: var(--lighterGray);
      border-color: var(--lighterGray);
      color: var(--white);
    }
  }
  & + & {
    margin-left: var(--margin);
  }
  ${below.xSmall`
    display: block;
    max-width: 100%;
    padding: var(--smallButtonPadding);
    & + & {
      margin: 20px 0 0 0;
    }
  `}
`

export const smallButtonStyle = css`
  padding: var(--smallButtonPadding);
  font-size: var(--smallFontSize);
  min-width: 0;
`

export const fullWidthStyle = css`
  width: 100%;
`

export const Button = styled.button<{
  size?: string
  color?: string
}>`
  ${baseButtonStyle};
  ${({ size }) => {
      if (size === 'small') {
        return smallButtonStyle
      }

      if (size === 'full') {
        return fullWidthStyle;
      }
  }};
  ${({ color }) => {
    if (color === 'white') return whiteButtonStyle
    if (color === 'outline') return outlineButtonStyle
    if (color === 'orange') return orangeButtonStyle
    if (color === 'green') return greenButtonStyle
    if (color === 'dark') return darkButtonStyle
    if (color === 'limegreen') return limeGreenButtonStyle

    return blueButtonStyle
  }};
`

export const TextButton = styled.button`
  border-radius: var(--buttonRadius);
  color: var(--primayColor);
  display: inline-block;
  text-transform: uppercase;
  font-weight: 500;
  transition: var(--transition);
  padding: var(--margin);
  &:hover {
    background: var(--lightesterGray);
  }
  &:disabled {
    background: var(--lighterGray);
    color: var(--white);
    cursor: auto;
    &:hover {
      background: var(--lighterGray);
      color: var(--white);
    }
  }
  ${below.small`
    padding: var(--margin);
  `}
`
