import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { QuantityInput } from 'components/inputs'
import HeadlineModal from 'components/elements/HeadlineModal'
import { below, media } from 'styles'
import { useCart } from 'global'
import { ShopifyProductVariant } from 'lib/shopify/storefront-api-client/types/custom.types'
import { DiscountResponse } from 'lib/retail-api'
import { getBasePrice, getDiscountedAmount } from 'utilities'
import { Price } from '../Price'
import { BaseCartLine } from 'lib/shopify/storefront-api-client/types/storefront.types'

type HeadlineModalProps = {
  headline: string
  modalContent: JSX.Element
}

export type QuantityUpsellProps = {
  // for realtime data fetching
  variant: ShopifyProductVariant
  header: JSX.Element
  contentHeadline?: string
  image: {
    gatsbyImageData: IGatsbyImageData,
    altText: string
  }
  imageAlt?: string
  currentQuantity: number
  quantityLimit: number
  onQuantityChange: (qty: number) => void
  modalContent ?: HeadlineModalProps
  discount?: DiscountResponse | null
}

const deduceQuantityLimit = (upsellVariant: ShopifyProductVariant, quantityLimit: number, lineItems?: BaseCartLine[]): number => {
  if (!lineItems) return 0;
  const currentQtyInCart = lineItems.reduce((acc, item) => {
    if (item.merchandise?.sku === upsellVariant.sku) return acc += item.quantity
    return acc;
  }, 0);

  if (currentQtyInCart > quantityLimit) return quantityLimit;
  return quantityLimit - currentQtyInCart;
}

export const QuantityUpsell = ({
  variant,
  header,
  image,
  imageAlt,
  currentQuantity,
  quantityLimit,
  onQuantityChange,
  modalContent,
  discount
}: QuantityUpsellProps) => {
  const { cart } = useCart();

  if (!variant) return null;

  const limit = deduceQuantityLimit(variant, quantityLimit, cart?.lines);

  if (limit === 0) return null;

  const discountAmount = discount ? getDiscountedAmount(
    variant.price.amount, 
    discount?.percentage ? 'percentage' : 'amount', 
    discount.percentage ? (discount.percentage * 100) : discount.amount
  ) : 0;

  return (
    <ItemStyles data-testid="quantity-upsell">
      <>
        <div className="quantity-upsell-content">
          <div className="image-container">
            <div>
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={imageAlt || image.altText}
              />
            </div>
          </div>
          <div className="heading-content">
            <div>{header}</div>
            <h5 className="no-margins price-wrapper">
              <Price
                basePrice={getBasePrice(variant)}
                discountAmount={discountAmount}
              />
              each
            </h5>
            <small>(Max {quantityLimit} per order)</small>
          </div>
          <div className="input-quantity">
            <QuantityInput
              increment={() => onQuantityChange(currentQuantity + 1)}
              decrement={() => onQuantityChange(currentQuantity === 0 ? 0 : currentQuantity - 1)}
              selectedAmount={currentQuantity}
              limit={limit}
            />
          </div>
        </div>

        {modalContent && (
          <HeadlineModal
            title={modalContent.headline}
            modalContent={() => modalContent.modalContent}
          />
        )}
      </>
    </ItemStyles>
  )
}

const ItemStyles = styled.div`
  margin-bottom: 2em;
  padding: 5px 10px;
  border-radius: var(--cardRadius);
  border: 1px solid var(--lineColor);
  .accordion {
    border-bottom: none;
  }
  .accordion-wrapper {
    padding: 10px 0;
  }
  .quantity-upsell-content {
    display: flex;
    align-items: center;
    position: relative;
    margin: 20px auto;
    .heading-content {
      width: calc(100% - 200px);
      padding: 0 10px;
    }
  }
  .content-headline {
    font-size: var(--h5);
    margin-bottom: 10px;
  }
  .image-container {
    ${media.small`
      width: 20%;
    `}

    border-radius: var(--cardRadius);
    width: 40%;
    aspect-ratio: 2/1.5;
    display: flex;
    align-items: center;
  }

  .input-quantity {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .price-wrapper {
    display: flex;
  }

  .price-text {
    display: flex;
    margin-right: 2px;
    .price {
      margin-right: 2px;
    }
  }

  ${below.mediumLarge`
    .image-container {
      display: none;
    }

    .quantity-upsell-content .heading-content {
      width: calc(100% - 93px);
      padding: 0 5px 0 0;
    }
  `}
`
