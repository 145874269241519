import { CART_FRAGMENT } from "./fragments/cart-fragment"

export const CART_LINE_ITEMS_REMOVE_MUTATION = `
  ${CART_FRAGMENT.fragment}

  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ${CART_FRAGMENT.ref}
      }
      userErrors {
        field
        message
      }
    }
  }
`
