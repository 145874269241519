import { FragmentHelpers } from "../../types"

export const CART_FRAGMENT: FragmentHelpers = {
  ref: '...CartFragment',
  fragment: `
    fragment CartFragment on Cart {
      id
      createdAt
      updatedAt
      checkoutUrl
      totalQuantity
      cost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalDutyAmount {
          amount
          currencyCode
        }
      }
      lines(first: 25) {
        nodes {
          id
          quantity
          attributes {
            key
            value
          }
          discountAllocations {
            discountedAmount {
              amount
              currencyCode
            }
          }
          cost {
            amountPerQuantity {
              amount
              currencyCode
            }
            compareAtAmountPerQuantity {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalAmount {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              title
              sku
              image {
                altText
                url
              }
              price {
                amount
                currencyCode
              }
              compareAtPrice {
                amount
                currencyCode
              }
              product {
                id
                title
                handle
                description
                tags
                media(first: 1) {
                  edges {
                    node {
                      ... on MediaImage {
                        image {
                          url
                          altText
                        }
                      }
                    }
                  }
                }
                images(first: 1) {
                  edges {
                    node {
                      url
                      altText
                    }
                  }
                }
              }
            }
          }
        }
      }
      attributes {
        key
        value
      }
      discountCodes {
        applicable
        code
      }
      discountAllocations {
        discountedAmount {
          amount
          currencyCode
        }
      }
    }
  `
}
