import { resolveCart } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { ShopifyCart } from "../types/custom.types";
import { Cart, CartCreatePayload } from "../types/storefront.types";
import { CART_CREATE_MUTATION } from "../graphql";

type CartResponse = { 
  cartCreate: {
    cart: Cart
  }
}
export const createCart = async (input?: CartCreatePayload): Promise<ShopifyCart> => {
  const { data, errors } = await storefrontAPIClient.request<CartResponse>(
    CART_CREATE_MUTATION, 
    { 
      variables: {
        input: input || { note: "" }
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.cartCreate) throw new Error('Could not create cart!');

  return resolveCart(data.cartCreate.cart) as unknown as ShopifyCart;
}