import { CART_FRAGMENT } from "./fragments/cart-fragment"

export const CART_LINE_ITEMS_ADD_MUTATION = `
  ${CART_FRAGMENT.fragment}

  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ${CART_FRAGMENT.ref}
      }
      userErrors {
        field
        message
      }
    }
  }
`
