import { resolveCart } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { ShopifyCart } from "../types/custom.types";
import { Cart } from "../types/storefront.types";
import { CART_LINE_ITEMS_REMOVE_MUTATION } from "../graphql";

type CartResponse = { 
  cartLinesRemove: {
    cart: Cart
  }
}
export const cartRemoveLineItems = async (cartId: string, lineIds: string[]): Promise<ShopifyCart> => {
  const { data, errors } = await storefrontAPIClient.request<CartResponse>(
    CART_LINE_ITEMS_REMOVE_MUTATION, 
    { 
      variables: {
        cartId,
        lineIds
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.cartLinesRemove) throw new Error('Could not remove lines in cart!');

  return resolveCart(data.cartLinesRemove.cart);
}