import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Base64 } from 'js-base64'
import { GatsbyMediaExternalVideo, GatsbyMediaImage, GatsbyShopifyProduct, GatsbyShopifyStorefrontProduct, ShopifyCart, ShopifyProduct } from 'lib/shopify/storefront-api-client/types/custom.types';
import { MoneyV2, Maybe, ExternalVideo, MediaImage } from 'lib/shopify/storefront-api-client/types/storefront.types';
import { Discount } from 'types/misc';
import { getShopifyImage } from './shopify-image';

export const encodeId = (str: string) => {
  return Base64.encode(str);
}

type VariantLike = {
  sku: string, 
  availableForSale: boolean
}

export const isProductDiscounted = (discount: Discount, product: { variants: VariantLike[] }) => {
  return discount.products?.some(p => product.variants.some(v => p.sku === v.sku && v.availableForSale));
}

/**
 * High leve util to just decode an ID, if not base64 just returns string
 */
export const decodeId = (str: string) => {
  return Base64.isValid(str) ? Base64.decode(str) : str;
}

// Encoding for shopify IDs
export const encodeShopifyId = (id) => {
  const shopifyIdUrl = 'gid://shopify/Product'
  return Base64.encode(`${shopifyIdUrl}/${id}`)
}

// Encoding for shopify variant  IDs
export const encodeShopifyVariantId = (id) => {
  const shopifyIdUrl = 'gid://shopify/ProductVariant'
  return Base64.encode(`${shopifyIdUrl}/${id}`)
}

/**
 * input: gid://shopify/ProductVariant/31979955552291'
 * output: '31979955552291'
 */
 export const fetchIdFromGlobalId = (id: string|number) => {
  return String(id).split('/').slice(-1)[0]
}

export const fetchIdFromGlobalLineItemId = (id: string|number) => {
  return String(id)
    .split('/')
    .slice(-1)[0]
    .split('?')[0]
}

export const getGlobalVariantId = (strippedVariantId: string|number) => {
  return `gid://shopify/ProductVariant/${strippedVariantId}`;
}

export const getGlobalProductId = (strippedProductId: string|number) => {
  return `gid://shopify/Product/${strippedProductId}`;
}

export const transformShopifyMedia = (
  shopifyProduct: ShopifyProduct | GatsbyShopifyProduct, 
  imageTransformationSettings?: { width?: number, height?: number  }
): GatsbyShopifyProduct | GatsbyShopifyStorefrontProduct => {
  const copyProduct = { ...shopifyProduct };
  const mapMediaImage = (mediaItem: any) => { 
    if (mediaItem?.image?.gatsbyImageData) return mediaItem; 

    const copyMediaItem = { ...mediaItem } as any;

    const originalSrc = copyMediaItem?.image?.originalSrc || copyMediaItem?.src || copyMediaItem.image?.src;

    copyMediaItem.image = {
      ...copyMediaItem.image,
      originalSrc,
      gatsbyImageData: getShopifyImage({
        image: {
          height: imageTransformationSettings?.width || 1600,
          width: imageTransformationSettings?.height || 1600,
          /**
           * convert .gif images since gatsby-image-plugin doesn't support .gif image transformation
           * and therefore gatsbyImageData return null and originalSrc will still be set
           * @source https://github.com/gatsbyjs/gatsby/issues/23598
           */
          originalSrc
        },
        width: imageTransformationSettings?.width || 1600,
        height: imageTransformationSettings?.height || 1600
      }) as IGatsbyImageData
    } as any;

    return copyMediaItem as GatsbyMediaImage;
  }

  const mapMediaExternalVideo = (mediaItem: ExternalVideo) => {
    const getThumbnailUrl = (source: { embeddedUrl: string }) => {
      // Assuming the thumbnail URL can be derived from the embeddedUrl
      // This is a placeholder logic, replace it with actual logic as needed
      const videoId = source.embeddedUrl.split('/').pop();
      return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    }
  
    const getVideoId = (source: { embeddedUrl: string }) => {
      return source.embeddedUrl.split('/').pop();
    }

    return {
      ...mediaItem,
      thumbnailUrl: getThumbnailUrl(mediaItem),
      videoId: getVideoId(mediaItem)
    }
  }

  const transformedMedia = copyProduct.media.map(mediaItem => {
    let copyMediaItem = { ...mediaItem };

    // only need to transform images at this point, 
    // external videos don't need to be transformed
    if (mediaItem.mediaContentType === 'IMAGE') {
      copyMediaItem = mapMediaImage(mediaItem);
    }

    if (mediaItem.mediaContentType === 'EXTERNAL_VIDEO') {
      copyMediaItem = mapMediaExternalVideo(mediaItem);
    }

    if (copyMediaItem.previewImage && !copyMediaItem.previewImage?.image?.gatsbyImageData) {
      copyMediaItem.previewImage = mapMediaImage(mediaItem.previewImage);
    }
    
    return copyMediaItem;
  })

  // append variant IDs to media items so that variant image selection still works
  shopifyProduct?.variants
    .filter(v => v.image)
    .forEach(v => {
      const imgIdx = transformedMedia.findIndex(img => {
        if (!v.image) return false;
        const removeCacheVersion = (url: string) => url.split('?v=')[0];
        const imgSrc = removeCacheVersion(v.image.originalSrc || v.image.src);
        const mediaSrc = removeCacheVersion(img.image.originalSrc || img.image.src);
        return imgSrc === mediaSrc;
      });
      if (imgIdx !== -1) {
        transformedMedia[imgIdx] = {
          ...transformedMedia[imgIdx],
          variantIds: (
            transformedMedia[imgIdx].variantIds?.length 
              ? [...transformedMedia[imgIdx].variantIds, v.id]
              : [v.id ]
          )
        }
      }
  })

  copyProduct.media = transformedMedia as (GatsbyMediaImage | GatsbyMediaExternalVideo)[];

  return copyProduct;
}

type VariantPriceLike = {
  price: MoneyV2
}
const sortByPrice = (a: VariantPriceLike, b: VariantPriceLike) => Number(a.price.amount) < Number(b.price.amount) ? -1 : 1;

export const transformGatsbyShopifyVariants = (shopifyProduct) => {
  const copyProduct = { ...shopifyProduct };

  copyProduct.variants = copyProduct.variants.map((variant) => {
    if (!variant.price.amount) {
      variant.price = {
        amount: String(variant.price),
        currencyCode: 'USD'
      }
    }

    return variant;
  }).sort(sortByPrice);

  return copyProduct
}

export const transformGatsbyShopifyProduct = (
  shopifyProduct: GatsbyShopifyProduct, 
  imageTransformationSettings?: { width: number, height: number  }
) => {
  return transformShopifyMedia(
    transformGatsbyShopifyVariants(
      shopifyProduct
    ),
    imageTransformationSettings
  )
}

export const transformShopifyStorefrontImagesToGatsbyImage = (
  product: ShopifyProduct,
  width?: number,
  height?: number
): GatsbyShopifyStorefrontProduct | GatsbyShopifyProduct | null => {
  return transformShopifyMedia(product, { width, height });
}

export const sortVariantsByPrice = (variants: VariantPriceLike[]) => (
  variants.sort(sortByPrice)
)

export enum DISOUNT_TEMPLATE_KEYWORDS {
  TIERED = 'TIERED',
  GIFT = 'GIFT'
}

export const isTieredDiscountApplied = (cart?: ShopifyCart) => {
  if (!cart?.discountCodes) return false;
  return cart.discountCodes.find(
    d => {
      if (!d.code) return false;

      return d.code.includes(DISOUNT_TEMPLATE_KEYWORDS.TIERED) && d.applicable;
    }
  )
}

export const isFreeGiftApplied = (cart?: ShopifyCart) => {
  if (!cart?.discountCodes) return false;
  const isApplied = Boolean(cart.discountCodes.find(
    d => {
      if (!d.code) return false;

      return d.code.includes(DISOUNT_TEMPLATE_KEYWORDS.GIFT) && d.applicable;
    }
  ))

  return isApplied
}

export const getTotalQuantityOfProductInCart = (
  variants: { id: string }[], 
  cart?: ShopifyCart
) => {
  if (!cart || !cart?.lines.length) return 0;

  const variantIds = variants.map(v => v.id);

  return cart.lines.reduce((prev, lineItem) => (
    variantIds.includes(lineItem.merchandise.id) 
      ? (prev + lineItem.quantity) 
      : prev
  ), 0)
}

export const isVariantInCart = (variantId: string, cart?: ShopifyCart) => {
  if (!cart) return false;

  return Boolean(
    cart.lines.find(lineItem => lineItem.merchandise.id === variantId)
  )
}

export const isServiceProduct = (product: { tags?: string[] }) => {
  if (!product.tags) return false;
  return product.tags.includes('Service');
}

export const isValidCompareAtPrice = (variant: { compareAtPrice?: Maybe<MoneyV2> }) => {
  return Boolean(variant.compareAtPrice && Number(variant.compareAtPrice.amount) !== 0);
}

type ReturnProductImage = { 
  image: {
    gatsbyImageData: IGatsbyImageData
  }, 
  altText?: string 
};
export const getDefaultShopifyProductImage = (product: any): ReturnProductImage  => {
  const images = product.media.filter(mediaItem => mediaItem.mediaContentType === 'IMAGE');

  if (!images[0].image.gatsbyImageData) {
    return {
      ...images[0],
      image: getShopifyImage(images[0].image)
    }
  }

  return images[0];
}


export const MAX_LINE_ITEM_QUANTITY = 15;