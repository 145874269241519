import React from 'react'
import styled, { css } from 'styled-components'
import { below } from 'styles'
import { Tooltip } from 'components/elements'
import { useCart, useDiscount } from 'global'
import { isBundleInCart } from '../../lib/pickystory';
import { isFreeGiftApplied, isTieredDiscountApplied } from 'utilities'

type Props = {
  forceShow?: boolean // Show discounts outside of promo window
  basePrice: number
  discountAmount?: number // Amount to be subtracted from price to show discount price
  type?: 'normal' | 'product' | 'sidebar' | 'variant' | 'tiny' // different styles depending on location
  priceTestId?: string
  discountTestId?: string
  isDiscountFlagShowing?: boolean // Shows discount flag if true (sidebar type only)
  isTooltipShowing?: boolean // Shows tooltip with message about override discounts
  roundDiscount?: boolean // determines whether to round the price or not, this is important given some inconsistencies between Shopify's discount calculation and Sanity's promo logic
}

export function Price({
  forceShow = false,
  basePrice,
  discountAmount = 0,
  isTooltipShowing = false,
  type = 'normal',
  priceTestId = 'full-price',
  discountTestId = 'discount-price',
  isDiscountFlagShowing = false,
}: Props) {
  const { getDiscountedPrice } = useDiscount();
  const { cart } = useCart();
  const higherPriorityDiscountsNotApplied = (
    !isTieredDiscountApplied(cart) && 
    !isFreeGiftApplied(cart) &&
    !isBundleInCart(cart)
  )

  const isDiscountShowing = (
    discountAmount > 0 
    && (higherPriorityDiscountsNotApplied || forceShow)
  )

  const discountPrice = getDiscountedPrice({ price: Number(basePrice), discountAmount });

  const BasePrice = (
    <>
      <div className="price no-margins" data-testid={priceTestId}>
        <span className='sr-only'>
          {
            isDiscountShowing 
              ? 'Original Price' 
              : 'Current Price'
          }
        </span>
                
        {
          isDiscountShowing 
            ? <span><s><span>{`$${basePrice.toFixed(2)}`}</span></s></span>
            : <span>{`$${basePrice.toFixed(2)}`}</span>
        }
      </div>
    </>
  )

  const DiscountPrice = (
    <div
      className="accent-text discount no-margins"
      data-testid={discountTestId}
    >
      {isDiscountShowing &&
        type === 'sidebar' && ( //We only want the tooltip to show when a discount is active and the full price is crossed out and the discount price is shown in green
          <Tooltip
            position="left"
            message="Additional discounts applied in cart"
          />
        )}
      <span className='sr-only'>
        Current Price
      </span>
      <span>{`$${discountPrice}`}</span>
    </div>
  )

  const DiscountFlag = (
    <span className="discount-flag" data-testid="discount-flag">
      ${discountAmount} OFF
    </span>
  )

  return (
    <PriceText isDiscountShowing={isDiscountShowing} type={type} className="price-text">
      {BasePrice}
      {!isTooltipShowing &&
        isDiscountShowing &&
        isDiscountFlagShowing &&
        DiscountFlag}
      {isDiscountShowing && DiscountPrice}
    </PriceText>
  )
}

const productPriceStyle = css`
  .price,
  .discount {
    text-align: right;
    margin-right: 5px;
    display: inline;
  }
  .discount-flag {
    display: none;
  }
`

const sidebarPriceStyle = css`
  text-align: right;
  .price,
  .discount {
    font-size: var(--h5);
    margin: 0;
  }
  .discount-flag {
    color: var(--green);
    background: rgba(130, 200, 97, 0.23);
    font-size: var(--smallestFontSize);
    font-weight: bold;
    padding: 0.25rem;
    width: max-content;
    margin-left: auto;
    white-space: nowrap;
  }
`

const variantPriceStyle = css`
  .price,
  .discount {
    margin: 0;
    font-weight: var(--fontWeightNormal);
  }
  ${({ isDiscountShowing }: { isDiscountShowing: boolean }) =>
    isDiscountShowing &&
    css`
      .price {
        margin-top: var(--smallMargin);
        color: var(--textColor);
        margin-right: 5px;
        text-decoration: line-through;
        font-weight: normal;
      }
      .discount-flag {
        display: none;
      }
    `};
  ${below.small`
    font-size: var(--largeFontSize);
  `};
`

const tinyPriceStyle = css`
  .price,
  .discount {
    margin: 0;
    font-weight: var(--fontWeightNormal);
    font-size: 16px;
  }
  ${({ isDiscountShowing }: { isDiscountShowing: boolean }) =>
    isDiscountShowing &&
    css`
      .price {
        margin-top: var(--smallMargin);
        color: var(--accentColor);
        margin-right: 5px;
        text-decoration: line-through;
        font-weight: normal;
      }
      .discount-flag {
        display: none;
      }
    `};
`

const PriceText = styled.div<{
  type: 'normal' | 'product' | 'sidebar' | 'variant' | 'tiny'
  isDiscountShowing: boolean
}>`
  ${({ type }) => {
    if (type === 'product') return productPriceStyle
    if (type === 'sidebar') return sidebarPriceStyle
    if (type === 'variant') return variantPriceStyle
    if (type === 'tiny') return tinyPriceStyle;
    return 'normal'
  }}
  .price {
    margin-top: 0;
  }
  .discount {
    font-weight: bold;
  }
  ${({ isDiscountShowing }) =>
    isDiscountShowing &&
    css`
      .price {
        color: var(--textColor);
        text-decoration: line-through;
        font-weight: normal;
        margin-bottom: 0;
      }
    `};
`
