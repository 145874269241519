import React, {
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react'
import { navigate } from 'gatsby'

import {
  getUrlParam,
  hasUrlParam,
  asyncForEach,
  skusExtend,
  fetchIdFromGlobalLineItemId,
  fetchIdFromGlobalId,
  getGlobalVariantId,
  MAX_LINE_ITEM_QUANTITY,
  daysToMs,
  getGlobalProductId,
  isValidCompareAtPrice
} from 'utilities'

import {
  trackProductAdded,
  trackProductRemoved,
  TrackingProductUnformatted,
  cartCustomAttributes,
  trackProductsAdded,
} from 'analytics'
import { useStorage } from 'hooks'
import { useLoad } from './loading'
import { ExtendVariant } from '../lib/extend';
import { CONTROLLER_PRODUCT_SKUS_MAP, PARTNER_PRODUCT_VARIANTS_BY_TITLE } from '../../common/config/products';
import { getBundleNameFromLineItem, isBundleLineItem } from '../lib/pickystory';
import { AttributeInput, BaseCartLine, CartLineInput, MoneyV2} from 'lib/shopify/storefront-api-client/types/storefront.types';
import { ShopifyCart } from 'lib/shopify/storefront-api-client/types/custom.types';
import { cartAddLineItems, cartRemoveLineItems, cartUpdateAttributes, cartUpdateDiscount, cartUpdateLineItems, createCart as createShopifyCart, fetchCart } from 'lib/shopify/storefront-api-client/cart';
import { useNotification } from './notification'

declare const window: any

interface LineItem {
  variantId: string
  name: string
  price: string
  quantity: number
  sku: string
}

export interface LineItemToAdd {
  /**
   * Extra information in the form of an array of Key-Value pairs about the line item.
   *
   * The input must not contain more than `250` values.
   */
  customAttributes?: AttributeInput[];
  /** The quantity of the line item. */
  quantity: number;
  /** The ID of the product variant for the line item. */
  variantId: string;
};

interface RemoveLineItem {
  id: string
  name: string
  price: string
  sku: string
  quantity: number
  variantId?: string | number
}

export interface LineItemInput extends LineItem {
  noRedirectToCart?: boolean
  customAttributes?: AttributeInput[]
  warranty?: {
    plan: any
    strippedProductId: string
    title: string
  }
}

export interface UpdateLineItem {
  id: string
  name: string
  price: string
  sku: string
  quantity: number
  isIncreasingQuantity: boolean
  quantityChanged: number
  variantId?: string | number
}

export enum CART_STATES {
  PENDING = 'PENDING',
  READY = 'READY',
  PRODUCT_ADDED = 'PRODUCT_ADDED',
  PRODUCT_REMOVED = 'PRODUCT_REMOVED',
  QUANTITY_UPDATED = 'QUANTITY_UPDATED',
  DISCOUNT_ADDED = 'DISCOUNT_ADDED',
  DISCOUNT_REMOVED = 'DISCOUNT_REMOVED'
}


export const initialCheckoutValues = {
  cartQuantity: 0,
  legacyAddVariantToCart: (_: LineItemInput) => {},
  // @ts-ignore
  addVariantsToCart: (lineItems: LineItemInput[], noRedirectToCart?: boolean, discountCodes?: string[]) => Promise.resolve(),
  // @ts-ignore
  addVariantsToCheckout: (lineItems: LineItemInput[], discountCode?: string) => Promise.resolve(),
  addWarrantyToCart: (
    plan: any,
    strippedProductId: string,
    title: string
  ) => {
    plan + strippedProductId + title
  },
  openCart: () => {},
  cart: {
    id: '',
    lines: [] as BaseCartLine[],
    checkoutUrl: '',
    cost: {
      subtotalAmount: {
        amount: '',
        currencyCode: 'USD'
      } as MoneyV2
    }
  } as ShopifyCart,
  updateQuantityInCart: async (_: UpdateLineItem) => {},
  removeLineItemInCart: (_: RemoveLineItem) => {},
  removeLineItemsInCart: (
    _: RemoveLineItem[]
  ) => {},
  updateCustomAttributes: () => {},
  shopifyError: '',
  applyDiscount: async (_code: string) => {},
  getCart: async () => {},
  cartState: CART_STATES.PENDING,
}


export const CartContext = createContext(initialCheckoutValues);

export const CartProvider = ({ children }) => {
  const [cartState, setCartState] = useState<CART_STATES>(CART_STATES.PENDING);
  const [cart, setCart] = useState<ShopifyCart>(null!)
  const [shopifyError, setShopifyError] = useState('')
  const [cartId, setCartId] = useStorage({ key: 'rachioCartId', expires: daysToMs(5) })
  const { setIsLoading } = useLoad()
  const { setNotification } = useNotification();

  const createCart = async () => {
    setCartState(CART_STATES.PENDING);
    setIsLoading(true)
    try {
      const shopifyCart = await createShopifyCart() as ShopifyCart
      setCartId(shopifyCart.id)
      setCart(shopifyCart)
      setIsLoading(false)
      setCartState(CART_STATES.READY);
    } catch (e) {
      console.log(e)
      setShopifyError('Our cart is temporarily experiencing difficulties.')
      setIsLoading(false)
    }
  }

  const getCart = async () => {
    let shopifyCartId = cartId
    // If cart id param is passed from abandonment email use it as the cart id
    if (hasUrlParam('cart_id')) {
      shopifyCartId = decodeURIComponent(getUrlParam('cart_id') || '');
      setCartId(shopifyCartId)
    }

    if (shopifyCartId) {
      try {
        let shopifyCart = await fetchCart(shopifyCartId) as ShopifyCart

        /**
         * If cached cart id returns null OR the cart returned is completed,
         * THEN reset cart cookies and make new cart.
         */
        if (!shopifyCart) {
          localStorage.removeItem('rachioCartId')
          return createCart()
        }

        setCart(shopifyCart)
        return setCartState(CART_STATES.READY);
      } catch (e) {
        createCart()
        console.log(e)
      }
    }
    return createCart()
  }

  useEffect(() => {
    getCart()
  }, [])

  const openCart = () => {
    navigate('/cart/');
  }

  /**
   * @todo refactor this so it can use addVariantsToCart instead of duplicating logic
   */
  const addVariantsToCheckout = async (lineItems: LineItemToAdd[], discountCode?: string) => {
    try {
      const oneTimeCart = await createShopifyCart();
      
      if (!oneTimeCart) throw new Error("Could not create cart!");

      const filterMaxQtyProducts = (lineItem: LineItemToAdd) => {
        const currentLineItemQuantity = cart.lines?.find(
          (item) => item.merchandise?.id === lineItem.variantId
        )?.quantity || 0

        return currentLineItemQuantity <= MAX_LINE_ITEM_QUANTITY
      }
        // Limits orders to MAX_LINE_ITEM_QUANTITY per item

      const lineItemsToAdd = lineItems.filter(filterMaxQtyProducts).map(lineItem => ({
        merchandiseId: lineItem.variantId,
        attributes: lineItem.customAttributes,
        quantity: lineItem.quantity
      })) as CartLineInput[]

      let updatedCart = await cartAddLineItems(oneTimeCart.id, lineItemsToAdd)

      if (!updatedCart) throw new Error('Could not add line items to cart!')

      if (updatedCart && discountCode) {
        updatedCart = await cartUpdateDiscount(cart.id, [discountCode]);
      }
      
      const variantsInCart = updatedCart?.lines?.filter(({ merchandise }) => (
        lineItemsToAdd.map(lineItemToAdd => lineItemToAdd.merchandiseId).includes(merchandise?.id as string)
      ))

      if (variantsInCart?.length && updatedCart) {
        const formattedTrackingProducts = variantsInCart.map(lineItem => {
          return {
            variantId: lineItem.merchandise.id,
            productId: lineItem.merchandise.product.id,
            quantity: lineItem.quantity,
            title: lineItem.merchandise.title,
            price: lineItem.merchandise.compareAtPrice && isValidCompareAtPrice(lineItem.merchandise)
              ? lineItem.merchandise.compareAtPrice.amount 
              : lineItem.merchandise.price.amount,
            sku: lineItem.merchandise.sku
          }
        }).filter(Boolean) as TrackingProductUnformatted[]

        trackProductsAdded(formattedTrackingProducts, updatedCart)
      }

      location.href = oneTimeCart.checkoutUrl
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  const addVariantsToCart = async (
    lineItems: LineItemToAdd[], 
    noRedirectToCart?: boolean, 
    discountCodes?: string[],
  ) => {
    try {
      if (!cart) throw new Error('Cart is not defined, could not add item to cart');
      setIsLoading(true);
      setCartState(CART_STATES.READY);

      const filterMaxQtyProducts = (lineItem: LineItemToAdd) => {
        const currentLineItemQuantity = cart.lines?.find(
          (item) => item.merchandise?.id === lineItem.variantId
        )?.quantity || 0

        return currentLineItemQuantity < MAX_LINE_ITEM_QUANTITY
      }
        // Limits orders to MAX_LINE_ITEM_QUANTITY per item

        const lineItemsToAdd = lineItems.filter(filterMaxQtyProducts).map(lineItem => ({
          merchandiseId: lineItem.variantId,
          attributes: lineItem.customAttributes,
          quantity: lineItem.quantity
        })) as CartLineInput[]

      let updatedCart = await cartAddLineItems(cart.id, lineItemsToAdd)

      if (discountCodes?.length) {
        updatedCart = await cartUpdateDiscount(cart.id, discountCodes);
      }
      
      const variantsInCart = updatedCart.lines.filter(({ merchandise }) => (
        lineItemsToAdd.map(lineItemToAdd => lineItemToAdd.merchandiseId).includes(merchandise.id)
      ))

      if (variantsInCart.length) {
        const formattedTrackingProducts = variantsInCart.map(lineItem => ({
            variantId: lineItem.merchandise.id,
            productId: lineItem.merchandise.product.id,
            quantity: lineItem.quantity,
            title: lineItem.merchandise.title,
            price: lineItem.merchandise.compareAtPrice 
              ? lineItem.merchandise.compareAtPrice.amount 
              : lineItem.merchandise.price.amount,
            sku: lineItem.merchandise.sku as string
          }
        ))

        trackProductsAdded(formattedTrackingProducts, updatedCart)
      }

      setCart(updatedCart);
      setIsLoading(false)
      setCartState(CART_STATES.READY);
      if (!noRedirectToCart) {
        openCart()
      }
    } catch (error: any) {
      setNotification({
        type: 'error',
        message: error.message
      })
      console.log(error)
      setIsLoading(false)
    }
  }

  /**
   * @todo REFACTOR TO ONLY WORK WITH WARRANTIES
   */
  const legacyAddVariantToCart = async ({
    variantId,
    quantity,
    name,
    price,
    sku,
    noRedirectToCart = false,
    customAttributes = [],
    warranty,
  }: LineItemInput) => {
    setIsLoading(true)
    setCartState(CART_STATES.READY);

    try {
      // Limits orders to MAX_LINE_ITEM_QUANTITY per item
      const currentLineItemQuantity =
        cart?.lines?.find((item) => item.merchandise?.id === variantId)
          ?.quantity || 0

      let updatedCart = cart
      //prevent more than MAX_LINE_ITEM_QUANTITY units per product

      if (currentLineItemQuantity < MAX_LINE_ITEM_QUANTITY) {
        let actualQuantity = quantity

        //match the Extend Quantity with the product quantity
        if (skusExtend.includes(sku)) {
          // retrivew product reference
          const refId = customAttributes.filter((attr) => attr.key === 'Ref')[0]
            .value
          let shopifyCart = await fetchCart(cartId)

          const RefQuantity =
             shopifyCart?.lines?.find((item) => {
              const variantId = String(item.merchandise.id)
                .split('/')
                .slice(-1)[0]
                .split('?')[0]

              return variantId === refId
            })?.quantity || 0
          actualQuantity = RefQuantity
        }

        updatedCart = await cartAddLineItems(cart.id, [
          { 
            merchandiseId: variantId, 
            quantity: actualQuantity, 
            attributes: customAttributes 
          },
        ])

        setCart(updatedCart)

        const variantInCart = updatedCart.lines.find(({ merchandise }) => merchandise?.id === variantId);

        if (variantInCart) {
          // productAdded tracking event
          const addedProduct: TrackingProductUnformatted = {
            variantId: variantId,
            productId: String(variantInCart?.merchandise?.product.id),
            quantity,
            title: name,
            price,
            sku
          }

          trackProductAdded(addedProduct, updatedCart)
        }
      }
      if (warranty?.plan) {
        window.ExtendShopify.getPlanVariant(
          {
            referenceId: warranty.strippedProductId,
            termLength: warranty.plan.term
          },
          async function (error: any, planVariant: { variantId: string }) {
            if (error) return console.log(error)
            var variantId = planVariant?.variantId
            const globalVariantId = getGlobalVariantId(variantId)

            //remove old warranty if exists
            const items = cart?.lines
            const oldWarranty = items?.find((item: BaseCartLine) =>
              item.attributes.some(
                (attr) => attr.value === warranty.strippedProductId
              )
            )
            let quantity = 1
            let previousQuantity = oldWarranty?.quantity || 0
            if (
              oldWarranty?.merchandise?.id &&
              oldWarranty?.merchandise.id !== globalVariantId &&
              oldWarranty?.merchandise.sku
            ) {
              await removeLineItemInCart({
                id: getGlobalVariantId(String(oldWarranty.id)),
                name: oldWarranty.merchandise.product.title,
                price: oldWarranty.merchandise.price.amount,
                sku: oldWarranty.merchandise.sku,
                quantity: oldWarranty.quantity,
              })
              const RefQuantity =
                updatedCart.lines?.find((item) => {
                  const variantId = String(item.merchandise.id)
                    .split('/')
                    .slice(-1)[0]
                    .split('?')[0]

                  return variantId === warranty.strippedProductId
                })?.quantity || 0
              quantity = RefQuantity
              previousQuantity = 0
            }
            if (!oldWarranty?.merchandise.id) {
              const RefQuantity =
                updatedCart.lines?.find((item) => {
                  const variantId = fetchIdFromGlobalLineItemId(item.merchandise.id);
                  return variantId === warranty.strippedProductId
                })?.quantity || 0
              quantity = RefQuantity
            }

            if (previousQuantity < MAX_LINE_ITEM_QUANTITY) {
              const adjustedCart = await cartAddLineItems(cart.id, [
                {
                  merchandiseId: globalVariantId,
                  quantity,
                  attributes: [
                    {
                      key: 'Ref',
                      value: warranty.strippedProductId,
                    },
                    {
                      key: 'Extend.IsExtendWarranty',
                      value: 'true'
                    },
                    { key: 'Product', value: warranty.title },
                    { key: 'Term', value: String(warranty.plan.term) },
                    { key: 'Price', value: String(warranty.plan.price) },
                    { key: 'Vendor', value: 'Extend' },
                  ],
                },
              ])

              const product: TrackingProductUnformatted = {
                productId: getGlobalProductId(warranty.strippedProductId),
                variantId: globalVariantId,
                quantity: quantity,
                title: warranty.plan.title,
                price: String(warranty.plan.price / 100),
                sku: warranty.plan.planId,
              }

              trackProductAdded(product, adjustedCart);
              setCart(adjustedCart);
              setCartState(CART_STATES.PRODUCT_ADDED);
            }
          }
        )
      } else {
        //checks if warranty is already in cart
        const warrantyInCart = updatedCart?.lines.find((item: BaseCartLine) =>
          item.attributes.some(
            (attr) => attr.value === warranty?.strippedProductId
          )
        )
        const currentQuantity = warrantyInCart?.quantity || 0
        const RefQuantity =
          updatedCart.lines?.find((item) => {
            const variantId = fetchIdFromGlobalLineItemId(item.merchandise.id);
            return variantId === warranty?.strippedProductId
          })?.quantity || 0

        if (warrantyInCart && warrantyInCart.quantity < MAX_LINE_ITEM_QUANTITY) {
          await updateQuantityInCart({
            id: String(warrantyInCart.id),
            name: warrantyInCart.merchandise.product.title,
            price: warrantyInCart.merchandise.price.amount,
            quantity: RefQuantity,
            sku: warrantyInCart.merchandise.sku as string,
            isIncreasingQuantity: currentQuantity < RefQuantity,
            quantityChanged: Math.abs(currentQuantity - RefQuantity),
          })
        }
      }

      setIsLoading(false)
      setCartState(CART_STATES.READY);
      if (!noRedirectToCart) {
        openCart()
      }
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  const applyDiscount = async (code: string) => {
    try {
      setCartState(CART_STATES.PENDING);
      const updatedCart = await cartUpdateDiscount(cart.id, [code]);  
      setCart(updatedCart);
      setCartState(CART_STATES.DISCOUNT_ADDED);
    } catch (error) {
      console.error(error)
    }
  }

  const addWarrantyToCart = async (
    plan: any,
    strippedProductId: string,
    title: string,
    updatedCart: any = null
  ) => {
    //add the recent warranty
    window.ExtendShopify.getPlanVariant(
      {
        referenceId: strippedProductId,
        termLength: plan.term
      },
      async function (error: any, planVariant: ExtendVariant) {
        if (error) return console.log(error)
        var variantId = planVariant?.variantId

        const globalVariantId = getGlobalVariantId(variantId);

        //remove old warranty if exists
        const items = updatedCart ? updatedCart : cart?.lines
        const oldWarranty = items?.find((item: BaseCartLine) =>
          item.attributes.some(
            (attr) => attr.value === strippedProductId
          )
        ) as BaseCartLine
        if (
          oldWarranty?.merchandise.id &&
          oldWarranty?.merchandise.id !== globalVariantId
        ) {
          await removeLineItemInCart({
            id: String(oldWarranty.id),
            name: oldWarranty.merchandise.product.title,
            price: oldWarranty.merchandise.price.amount,
            sku: oldWarranty.merchandise.sku as string,
            quantity: oldWarranty.quantity,
          })
        }

        await legacyAddVariantToCart({
          variantId: globalVariantId,
          quantity: 1,
          name: plan.title,
          price: plan.price,
          sku: plan.planId,
          customAttributes: [
            {
              key: 'Ref',
              value: strippedProductId,
            },
            { key: 'Product', value: title },
            { key: 'Term', value: String(plan.term) },
            { key: 'Price', value: String(plan.price) },
            { key: 'Vendor', value: 'Extend' },
          ],
          noRedirectToCart: true,
        })
      }
    )
  }

  const updateQuantityInCart = async ({
    id,
    name,
    price,
    quantity,
    sku,
    isIncreasingQuantity,
    quantityChanged,
    variantId = '',
  }: UpdateLineItem) => {
    if (quantity <= 0) {
      return removeLineItemInCart({ id, name, price, sku, quantity })
    }
    setCartState(CART_STATES.PENDING);
    setIsLoading(true)

    try {
      const lineItem = cart.lines.find(l => l.id === id) as BaseCartLine;
      const updatedCart = await cartUpdateLineItems(cart.id, [
        { id, quantity },
      ])
      setCart(updatedCart)
      setCartState(CART_STATES.QUANTITY_UPDATED)

      if (lineItem) {
        const product: TrackingProductUnformatted = {
          productId: lineItem.merchandise.product.id,
          variantId: lineItem.merchandise.id,
          quantity: quantityChanged,
          title: name,
          price,
          sku
        }
  
        if (lineItem && isBundleLineItem(lineItem)) {
          product.bundle = getBundleNameFromLineItem(lineItem) || ""
        }
  
        if (isIncreasingQuantity) {
          trackProductAdded(product, updatedCart)
        } else {
          trackProductRemoved(product, updatedCart)
        }
      }

      //----- block to match quantity with the Extend Warranty
      if (CONTROLLER_PRODUCT_SKUS_MAP[sku]) {
        const r3VariantId = fetchIdFromGlobalId(variantId);
        const item = updatedCart.lines.find((item) => {
          return (
            item.attributes.filter((attr) => attr.key === 'Ref')[0]
              ?.value === r3VariantId
          )
        })
        if (item) {
          updateQuantityInCart({
            id: String(item.id),
            name: item.merchandise.product.title,
            price: item.merchandise.price.amount,
            quantity: quantity,
            sku: item.merchandise.sku as string,
            isIncreasingQuantity,
            quantityChanged,
          })
        }
      }
      //----- /block to match quantity with the Extend Warranty

      setIsLoading(false)
    } catch (e: any) {
      setNotification({
        type: 'error',
        message: e.message
      })
      console.log(e)
      setIsLoading(false)
    }
  }

  const removeLineItemsInCart = (variants: RemoveLineItem[]) => {
    asyncForEach(variants, removeLineItemInCart)
  }

  const removeLineItemInCart = async ({
    id,
    name,
    price,
    sku,
    quantity,
    variantId = '',
  }: RemoveLineItem) => {
    setCartState(CART_STATES.PENDING);
    setIsLoading(true)
    try {
      const lineItemToRemove = cart.lines.find(l => l.id === id);
      const updatedCart = await cartRemoveLineItems(cart.id, [id]);

      if (lineItemToRemove) {
        const product: TrackingProductUnformatted = {
          productId: lineItemToRemove.merchandise.product.id,
          variantId: lineItemToRemove.merchandise.id,
          title: name,
          price,
          sku,
          quantity,
        }
  
        if (isBundleLineItem(lineItemToRemove)) {
          product.bundle = getBundleNameFromLineItem(lineItemToRemove) || ""
        }
  
        trackProductRemoved(product, updatedCart)
      }

      setCartState(CART_STATES.DISCOUNT_REMOVED);
      setCart(updatedCart)
      
      setIsLoading(false)

      //----- block to match quantity with the Extend Warranty & Weatherflow Tempest Bundle
      if (CONTROLLER_PRODUCT_SKUS_MAP[sku]) {
        const r3VariantId = fetchIdFromGlobalId(variantId);
        const extendLineItem = updatedCart.lines.find((item) => {
          return (
            item.attributes.filter((attr) => attr.key === 'Ref')[0]
              ?.value === r3VariantId
          )
        })
        const wftItem = updatedCart.lines.find((item) => {
          return (
            item.merchandise.title == PARTNER_PRODUCT_VARIANTS_BY_TITLE.WEATHERFLOW_TEMPEST.BUNDLE
          )
        })
        if (extendLineItem) {
          await removeLineItemInCart({
            id: String(extendLineItem.id),
            name: extendLineItem.merchandise.product.title,
            price: extendLineItem.merchandise.price.amount,
            sku: extendLineItem.merchandise.sku as string,
            quantity: extendLineItem.quantity,
          })
        }

        if (wftItem) {
          await removeLineItemInCart({
            id: String(wftItem.id),
            name: wftItem.merchandise.product.title,
            price: wftItem.merchandise.price.amount,
            sku: wftItem.merchandise.sku as string,
            quantity: wftItem.quantity,
          })
        }

      }
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  // These attributes are added to the cart object and sent to shopify
  const updateCustomAttributes = async (
    newAttributes: AttributeInput[] = []
  ) => {
    setIsLoading(true)
    try {
      const attributes: AttributeInput[] = cartCustomAttributes(newAttributes)

      const updatedCart = await cartUpdateAttributes(cart.id, attributes)

      setCart(updatedCart)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  const cartQuantity = cart?.totalQuantity || 0

  return (
    <CartContext.Provider
      value={{
        cartQuantity,
        legacyAddVariantToCart,
        addWarrantyToCart,
        addVariantsToCart,
        addVariantsToCheckout,
        openCart,
        cart,
        updateQuantityInCart,
        removeLineItemInCart,
        removeLineItemsInCart,
        updateCustomAttributes,
        shopifyError,
        applyDiscount,
        getCart,
        cartState,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCart = () => useContext(CartContext)
