import { resolveCart } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { ShopifyCart } from "../types/custom.types";
import { Cart } from "../types/storefront.types";
import { CART_QUERY } from "../graphql";

type CartResponse = { 
  cart: Cart
}
export const fetchCart = async (id?: string): Promise<ShopifyCart> => {
  const { data, errors } = await storefrontAPIClient.request<CartResponse>(
    CART_QUERY, 
    { 
      variables: {
        id
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.cart) throw new Error('Could not create cart!');

  return resolveCart(data.cart);
}