import { trackEvent, TrackFunction } from "analytics"
import { ShoppingEventInputs, SHOPPING_EVENT_NAMES } from "."
import { getTrackedPropertiesFromVariant, getProductsInCart } from "./transformers/product"
import { TrackingProductUnformatted } from "./types"
import { ShopifyCart } from "lib/shopify/storefront-api-client/types/custom.types"

const track: TrackFunction<SHOPPING_EVENT_NAMES.PRODUCT_REMOVED, ShoppingEventInputs[SHOPPING_EVENT_NAMES.PRODUCT_REMOVED]> = async (
  eventName,
  properties
) => {
  await trackEvent(eventName, properties);
}

export const trackProductRemoved = async (
  variant: TrackingProductUnformatted,
  cart: ShopifyCart 
) => {
  if (!variant || !cart) return;

  await track(
    SHOPPING_EVENT_NAMES.PRODUCT_REMOVED, 
    {
      cart_id: cart.id,
      ...getTrackedPropertiesFromVariant(variant),
      products: getProductsInCart(cart),
    }
  )
}