import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { Portal } from "./Portal"
import styled from "styled-components"
import { Icon } from "./Icon"
import { navigate } from "gatsby"
import { below } from "styles"
import { INotificationMessage, useNotification } from "global"

export const Notification = () => {
  const { notification, setNotification } = useNotification();

  const notificationIcon = (notification: INotificationMessage) => {
    switch (notification.type) {
      case 'cart': 
        return 'cart';
      case 'success':
        return 'check'
      case 'error':
        return 'caution'
      case 'warning' :
        return 'warning'
      default: 
        return 'check'
    }
  }

  const onLinkClick = () => {
    if (notification?.link) {
      navigate(notification.link);
      setNotification(null);
    }
  }
  
  return (
    <Portal id="notification-root">
      <AnimatePresence>
        {notification && (
          <NotificationMessage
            exit={{ opacity: 0, y: -100 }}
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <div className={`icon-wrapper ${notification.type}`}>
              <Icon name={notificationIcon(notification)} />
            </div>
            <div className="notification-text">
              <span>{notification.message}</span>
              {notification.link && (
                <a onClick={onLinkClick}>{notification.linkText || 'Learn more'}</a>
              )}
            </div>
            <button onClick={() => setNotification(null)}>
              <Icon 
                name="remove"
                color="var(--lightGray)"
              />
            </button>
          </NotificationMessage>
        )}
      </AnimatePresence>
    </Portal>
  )
}

const NotificationMessage = styled(motion.div)`
  --notificationSize: 7rem;
  --notificationFontSize: 1.4rem;
  width: 30%;
  height: var(--notificationSize);
  position: fixed;
  font-size: var(--notificationFontSize);
  top: 0;
  left: 35%;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  box-shadow: black 1px 3px 5px -5px;
  .notification-text {
    flex: 1;
    padding: 0 .5rem;
    line-height: 2rem;
  }
  .icon-wrapper {
    height: 100%;
    width: var(--notificationSize);
    background: var(--successColor);
    &.error {
      background: var(--dangerColor);
    }
    &.warning {
      background: var(--warningColor);
    }
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      stroke: white;
      width: 30px;
      height: 30px;
    }
  }
  a {
    margin-left: 5px;
    text-decoration: underline;
    font-weight: bold;
    font-size: var(--notificationFontSize);
  }

  button {
    position: absolute; 
    top: 0;
    right: 0;
    svg {
      width: 25px;
      height: 25px;
      margin-bottom: var(--smallMargin);
      circle {
        stroke: transparent;
      }
    }
  }

  @media (max-width: 1700px) {
    width: 40%;
    left: 30%;
  }

  ${below.xLarge`
    width: 45%;
    left: 27.5%;
  `}

  ${below.large`
    width: 60%;
    left: 20%;
  `}

  ${below.medium`
    --notificationFontSize: 1.2rem;
    width: 100%;
    left: 0;
  `}

  ${below.small`
    .icon-wrapper {
      width: 5rem;
      svg {
        width: 22px;
        height: 22px;
      }
    }
  `}
`