import { useEffect, useState } from 'react'
import { useCart } from 'global'
import { fetchIdFromGlobalId } from 'utilities'

export const skusExtend = [
  '10001-misc-elec-base-replace-1y',
  '10001-misc-elec-base-replace-2y',
  '10001-misc-elec-base-replace-3y',
  'B0-HGGEN-1y',
  'B0-HGGEN-2y',
  'B0-HGGEN-3y',
]

export const extendIsAlreadyInCart = (variantId: string): boolean => {
  const strippedId = fetchIdFromGlobalId(variantId);
  const { cart } = useCart()
  const [isInCart, setIsInCart] = useState(false)

  useEffect(() => {
    if (cart) {
      const found = cart.lines.some((lineItem) =>
        lineItem.attributes.some((attr) => attr.value === strippedId)
      )
      setIsInCart(found)
    }
  }, [cart])

  return isInCart
}
