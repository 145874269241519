import { CART_FRAGMENT } from "./fragments/cart-fragment";

export const CART_QUERY = `
  ${CART_FRAGMENT.fragment}

  query fetchCart($id: ID!){
    cart(id: $id) {
      ${CART_FRAGMENT.ref}
    } 
  }
`